$width-xss: 375px;
$width-sm: 690px;
$width-md: 912px;
$width-lg: 1040px;
$width-xl: 1194px;

$width-wide-start: $width-sm;

@mixin layoutWide($from-width: $width-wide-start) {
  @container (width >= #{$from-width}) {
    @content;
  }
}
