@import 'import';
@import '../helpers/layoutWide';

$svg-shift: 10%;

.root {
  position: relative;
  overflow: hidden;

  svg {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 120%;

    :global(._align-left) > & {
      left: calc(50% + $svg-shift);
    }

    :global(._align-center) > & {
      left: 50%;
    }

    :global(._align-right) > & {
      left: calc(50% - $svg-shift);
    }

    circle {
      stroke: var(--leaderboard-color-cover-circle);
      stroke-width: 76.42px;

      @include layoutWide($width-sm) {
        stroke-width: 58.83px;
      }

      @include layoutWide($width-md) {
        stroke-width: 47.13px;
      }

      @include layoutWide($width-lg) {
        stroke-width: 43.63px;
      }

      @include layoutWide($width-xl) {
        stroke-width: 40px;
      }
    }

    path {
      fill: var(--leaderboard-color-cover-side);
    }
  }
}
