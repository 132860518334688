@import 'import';
.PromoBannerDouble {
  $label-padding: rem(20);
  $label-width: 65%;
  $arrow-padding: rem(30);
  $banners-gap: rem(15);
  $banners-gap-sm: rem(10);

  --promo-banner-double-border-radius: 8px;

  display: flex;
  flex-flow: row nowrap;
  gap: $banners-gap;

  max-width: max-content;
  margin-right: auto;
  margin-left: auto;

  &-wrapper {
    @include media-breakpoint-down(lg) {
      margin-bottom: rem(40);
    }
  }

  &__HalfBanner {
    position: relative;

    overflow: hidden;
    display: block;
    flex: 1 1 auto;

    box-sizing: border-box;
    aspect-ratio: auto;
    min-width: 348px;
    max-width: 364px;
    min-height: rem(200);

    background-color: $grey-bb;
    border-radius: var(--promo-banner-double-border-radius);

    &:focus-visible {
      border: 1px solid $black;
    }

    &__image {
      position: absolute;

      width: 100%;
      height: 100%;

      object-fit: cover;
      border-radius: var(--promo-banner-double-border-radius);
    }

    &__label {
      position: absolute;
      bottom: $label-padding;
      left: $label-padding;

      width: $label-width;

      font-size: rem(30);
      font-weight: 700;
      line-height: rem(40);
      color: $white;
      text-transform: uppercase;
    }

    &__arrow {
      position: absolute;
      right: $arrow-padding;
      bottom: $arrow-padding;

      display: flex;
      align-items: center;
      justify-content: center;

      width: rem(40);
      height: rem(40);

      background-color: $white;
      border-radius: 50%;

      svg,
      span,
      .Icon.Arrow {
        display: block;
        width: rem(35);
        height: rem(35);
        fill: $red;
      }
    }

    @include media-breakpoint-down(xl) {
      &__label {
        font-size: rem(23);
        line-height: rem(30);
      }

      &__arrow {
        width: rem(30);
        height: rem(30);

        svg,
        span,
        .Icon.Arrow {
          width: rem(27);
          height: rem(27);
        }
      }
    }

    @include media-breakpoint-down(lg) {
      aspect-ratio: unset;
      height: rem(200);

      &__label {
        font-size: rem(20);
        line-height: rem(25);
      }

      &._left,
      &._right {
        flex: 1 0 rem(444);
      }

      &__arrow {
        width: rem(24);
        height: rem(24);

        svg,
        span,
        .Icon.Arrow {
          width: rem(21);
          height: rem(21);
        }
      }
    }

    @include media-breakpoint-down(xs) {
      height: rem(169);

      &__label {
        font-size: rem(15);
        line-height: rem(20);
      }

      &._left,
      &._right {
        flex: 1 0 rem(313);
      }
    }

    &._contentAlignBottomRight &__label {
      right: $label-padding;
      left: unset;
      text-align: right;
    }

    &._contentAlignTopLeft &__label {
      top: $label-padding;
      bottom: unset;
    }

    &._contentAlignTopRight &__label {
      top: $label-padding;
      right: $label-padding;
      bottom: unset;
      left: unset;

      text-align: right;
    }
  }

  &__Appearance {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }

  &__HalfBanner._contentAlignBottomLeft &__Appearance {
    scale: 1 1;
  }

  &__HalfBanner._contentAlignBottomRight &__Appearance {
    scale: -1 1;
  }

  &__HalfBanner._contentAlignTopLeft &__Appearance {
    scale: 1 -1;
  }

  &__HalfBanner._contentAlignTopRight &__Appearance {
    scale: -1 -1;
  }

  &__HalfBanner._contentAlignBottomRight &__HalfBanner__arrow {
    top: $arrow-padding;
    right: $arrow-padding;
    bottom: unset;
  }

  &__HalfBanner._overlayColorOceanBlue &__Appearance {
    &__VStyle {
      path {
        fill: $blue-purple;
      }

      path:nth-child(3n) {
        fill: $blue-purple;
      }
    }

    &__PortHole {
      ellipse {
        stroke: rgba($blue-purple-dark, 0.75);
      }

      path {
        fill: $blue-purple;
      }
    }
  }

  &__HalfBanner._overlayColorRockstar &__Appearance {
    &__VStyle {
      path {
        fill: $brass-cabin;
      }

      path:nth-child(3n) {
        fill: $brass-cabin;
      }
    }

    &__PortHole {
      ellipse {
        stroke: rgba($brass-cabin-dark, 0.75);
      }

      path {
        fill: $brass-cabin;
      }
    }
  }

  &__HalfBanner._overlayColorRockstarDark &__Appearance {
    &__VStyle {
      path {
        fill: $brass-cabin;
      }

      path:nth-child(3n) {
        fill: $black;
      }
    }

    &__PortHole {
      ellipse {
        stroke: rgba($brass-cabin-dark, 0.75);
      }

      path {
        fill: $black;
      }
    }
  }

  &__HalfBanner._overlayColorSquid &__Appearance {
    &__VStyle {
      path {
        fill: $purple;
      }

      path:nth-child(3n) {
        fill: $purple;
      }
    }

    &__PortHole {
      ellipse {
        stroke: rgba($purple-dark, 0.75);
      }

      path {
        fill: $purple;
      }
    }
  }

  &__HalfBanner._overlayColorUplift &__Appearance {
    &__VStyle {
      path {
        fill: $uplift;
      }

      path:nth-child(3n) {
        fill: $uplift;
      }
    }

    &__PortHole {
      ellipse {
        stroke: rgba($uplift-dark, 0.75);
      }

      path {
        fill: $uplift;
      }
    }
  }

  &__HalfBanner._overlayColorVirginRed &__Appearance {
    &__VStyle {
      path {
        fill: $red;
      }

      path:nth-child(3n) {
        fill: $red;
      }
    }

    &__PortHole {
      ellipse {
        stroke: $red-dark;
      }

      path {
        fill: $red;
      }
    }
  }

  &__HalfBanner._overlayColorVirginRedDark &__Appearance {
    &__VStyle {
      path {
        fill: $red;
      }

      path:nth-child(3n) {
        fill: $black;
      }
    }

    &__PortHole {
      ellipse {
        stroke: $red;
      }

      path {
        fill: $black;
      }
    }
  }

  @container (width <= 992px) {
    --promo-banner-double-border-radius: 6px;
  }

  @container (width <= 768px) {
    --promo-banner-double-border-radius: 5px;
  }

  @container (width <= 576px) {
    --promo-banner-double-border-radius: 4px;
  }

  @include media-breakpoint-down(xs) {
    gap: $banners-gap-sm;
  }
}

.MetaCategory .PromoBannerDouble-wrapper {
  margin-bottom: 60px;

  @include media-breakpoint-down(xl) {
    width: 95%;
    margin-bottom: 40px;
  }

  @include media-breakpoint-down(md) {
    width: 90%;
    margin-bottom: 30px;
  }
}
