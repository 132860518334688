@import 'import';
@import '../helpers/layoutWide';

$svg-size: 192%;
$svg-offset-y: -21.11%;
$svg-edge: -7.03%;
$svg-shift: 8.53%;
$svg-edge-mobile: -13.58%;
$svg-shift-mobile: 13.85%;
$bar-step: 20px;
$bar-step-mobile: 17px;

.root {
  position: relative;
  overflow: hidden;
}

.side {
  $scale-factor: 100% / $svg-size;

  position: absolute;
  inset: 0;

  svg {
    --leaderboard-vframe-offset-x-short: #{$scale-factor * ($svg-edge-mobile - $svg-shift-mobile) - 50%};
    --leaderboard-vframe-offset-x-medium: #{$scale-factor * $svg-edge-mobile - 50%};
    --leaderboard-vframe-offset-x-long: #{$scale-factor * ($svg-edge-mobile + $svg-shift-mobile) - 50%};
    --leaderboard-vframe-bar-step: #{$bar-step-mobile};

    @include layoutWide() {
      --leaderboard-vframe-offset-x-short: #{$scale-factor * ($svg-edge - $svg-shift) - 50%};
      --leaderboard-vframe-offset-x-medium: #{$scale-factor * $svg-edge - 50%};
      --leaderboard-vframe-offset-x-long: #{$scale-factor * ($svg-edge + $svg-shift) - 50%};
      --leaderboard-vframe-bar-step: #{$bar-step};
    }

    position: absolute;
    width: $svg-size;

    &:is(:nth-child(3n + 1), :nth-child(3n + 2)) {
      fill: var(--leaderboard-color-cover-bar);
      fill-opacity: 0.6;
    }

    &:nth-child(3n + 3) {
      fill: var(--leaderboard-color-cover-side);
    }
  }

  &:global(._left) svg {
    :global(._align-left) > .root & {
      transform: translate(var(--leaderboard-vframe-offset-x-long), $svg-offset-y);
    }

    :global(._align-center) > .root & {
      transform: translate(var(--leaderboard-vframe-offset-x-medium), $svg-offset-y);
    }

    :global(._align-right) > .root & {
      transform: translate(var(--leaderboard-vframe-offset-x-short), $svg-offset-y);
    }

    &:nth-child(3n + 1) {
      left: 0;
    }

    &:nth-child(3n + 2) {
      left: calc(-1 * var(--leaderboard-vframe-bar-step));
    }

    &:nth-child(3n + 3) {
      left: calc(-2 * var(--leaderboard-vframe-bar-step));
    }
  }

  &:global(._right) svg {
    $offset-base: 100% * $scale-factor - 100%;

    :global(._align-left) > .root & {
      transform: translate(calc(#{$offset-base} - var(--leaderboard-vframe-offset-x-short)), $svg-offset-y);
    }

    :global(._align-center) > .root & {
      transform: translate(calc(#{$offset-base} - var(--leaderboard-vframe-offset-x-medium)), $svg-offset-y);
    }

    :global(._align-right) > .root & {
      transform: translate(calc(#{$offset-base} - var(--leaderboard-vframe-offset-x-long)), $svg-offset-y);
    }

    &:nth-child(3n + 1) {
      left: 0;
    }

    &:nth-child(3n + 2) {
      left: var(--leaderboard-vframe-bar-step);
    }

    &:nth-child(3n + 3) {
      left: calc(2 * var(--leaderboard-vframe-bar-step));
    }
  }
}
